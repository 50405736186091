<script>
import { Bar } from '@/vue-chartjs'
import { formatDate, formatCurrency } from '@/misc/filters'

export default {
  extends: Bar,
  props: {
    datasets: Array,
    currency: String,
    currencyFormat: {
      type: Object,
      default: () => ({})
    },
    granularity: String
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              stacked: true,
              offset: true,
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value =>
                  formatCurrency(value, this.currency, this.currencyFormat)
              },
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          mode: 'x',
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              return formatDate(point.t, this.granularity)
            },
            label: (item, { datasets }) => {
              if (
                typeof item.yLabel === 'number' &&
                !Number.isNaN(item.yLabel)
              ) {
                const dataset = datasets[item.datasetIndex]
                const amount = formatCurrency(
                  item.yLabel,
                  this.currency,
                  this.currencyFormat
                )
                return `${dataset.label}: ${amount}`
              }
              return undefined
            },
            footer: (items, { datasets }) => {
              if (items.length < 2) {
                return ''
              }
              let total = 0
              items.forEach(({ datasetIndex, index }) => {
                const dataset = datasets[datasetIndex]
                const point = dataset.data[index]
                if (typeof point.y === 'number' && !Number.isNaN(point.y)) {
                  total += point.y
                }
              })
              const amount = formatCurrency(
                total,
                this.currency,
                this.currencyFormat
              )
              return `Total: ${amount}`
            }
          }
        }
      }
    }
  },
  watch: {
    datasets: {
      handler() {
        this.doRender()
      }
    }
  },
  mounted() {
    this.doRender()
  },
  methods: {
    doRender() {
      if (!this.datasets) return
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = this.granularity
      this.renderChart({ datasets: this.datasets }, this.options)
    }
  }
}
</script>
