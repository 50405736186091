<template lang="pug">
.chart
  b-loading(:active="loading" :is-full-page="true" :can-cancel="false")
  simple-bar(v-bind='data' v-if='data')
</template>

<script>
import frameData from '@/misc/embed-transport'
import SimpleBar from '@/components/charts/SimpleBar.vue'

export default {
  name: 'EmbedBar',
  components: {
    SimpleBar
  },
  data() {
    return { loading: true, data: null }
  },
  mounted() {
    frameData.then(
      ({ granularity, currency, currencyFormat = {}, datasets, data }) => {
        const formattedData = datasets.map(({ label, color }, idx) => {
          const seriesData = []
          data.forEach(row => {
            const t = row[0]
            let y = row[idx + 1]
            if (typeof y !== 'number') y = undefined
            seriesData.push({ t, y })
          })
          return {
            label,
            backgroundColor: color,
            data: seriesData
          }
        })
        this.loading = false
        this.data = {
          granularity,
          currency,
          currencyFormat,
          datasets: formattedData
        }
      }
    )
    document.getElementsByTagName('html')[0].style = 'overflow: hidden'
  },
  metaInfo: {
    title: 'Embedded Bar Chart'
  }
}
</script>
<style scoped lang="sass">
.chart
  position: relative
</style>
